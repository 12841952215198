.incidents-items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.incident-scheduled--period{
    text-align: center;
    font-size: 22px;
    color: #03a9f4;
}
.incidents-item {
    margin-bottom: 20px;
}
.incidents-items .incident_item-wrapper {}
.incidents-item .incident--name {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.incidents-item .incident--name .scheduled-tag{
    font-size: 12px;
    font-weight: normal;
    text-transform: uppercase;
    background: #2196f3;
    color: white;
    border-radius: 4px;
    margin-right: 6px;
    padding: 2px 6px;
}
.incidents-item .incident--name a{
    color:#172B4D;
    text-decoration : none;
}
.incidents-item .incident--name a:hover{
    text-decoration :underline;
}
.incidents-item .incident--body {}
.incidents-item .incident-resolved_period {
    font-size: 12px;
    color: #00c100;
}
.incidents-item .incident-events {
    font-size: 14px;
}
.incidents-item .incident-events ul{
    padding-left: 10px;
}
.incidents-item .incident-events li {
    margin-bottom: 0;
    display: flex;
}
.incidents-item .incident-events .historyEvents--timeline {
    flex: 0 0 30px;
    align-self: stretch;
    position: relative;
    text-align: center;
}
.incidents-item .incident-events .historyEvents--timeline:before  {
        content: '';
        position: absolute;
        left: calc(50% - 1px);
        z-index: 0;
        width: 2px;
        height: 100%;
        background-color: #d0d0d069;
}
.incidents-item .incident-events .historyEvents--timeline .timeline-icon {
    display: inline-block;
    position: relative;
    z-index: 1;
    margin-top: 6px;
}
.incidents-item .incident-events .historyEvents--timeline .timeline-icon .status-icon {
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    border: 2px solid #82828285;
    border-radius: 2px;
    background: #ffffff no-repeat 50% 50%;
    background-size: 100% 100%;
    text-indent: -9999px;
    width: 10px;
    height: 10px;
}
.incidents-item .incident-events .historyEvents--item {
    display: flex;
    margin-left: 6px;
    align-items: flex-start;
    flex-direction: row;
    padding: 6px 0;
    flex-grow: 1;
}
.incidents-item .affected-services{}
.incidents-item .affected-services > label{
    font-weight: 500;
}
.incidents-item .historyEvents--item .event-type{
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
    padding: 4px 6px;
    background: #efefef;
    border: 0px;
    border-radius: 3px;
    color: #333;
    flex: 0 0 110px;
    text-align: center;
}
.incidents-item .historyEvents--item .event-type.investigating {
    /* color: #ff0c04; */
}
.incidents-item .historyEvents--item .event-type.identified {
    /* color: #ebb000; */
}
.incidents-item .historyEvents--item .event-type.monitoring {
    /* color: #0064f9; */
}
.incidents-item .historyEvents--item .event-type.resolved {
    /* color: #22c529; */
}
.incidents-item .incident-events .historyEvents--item .event-msg_content {
    margin-left: 11px;
}
.incidents-item .incident-events .historyEvents--item .msg {

}
.incidents-item .incident-events .historyEvents--item .outline-info {}
.incidents-item .incident-events .historyEvents--item .outline-info .outline_info-date {
    font-size: 12px;
    color: #6C798F;
}
.incident-details .incident--name{
    font-size: 36px;
    color:#172B4D;
    width: 100%;
    justify-content: center;
}
.incident-details .incident--name,
.incident-details .incident-resolved_period{
    text-align: center;
}
.incident-details .incident--body{
    margin-top: 30px;
}
.incident-details .historyEvents--item{
    padding:14px 0 ; 
}
.incident-details .event-msg_content{
    line-height: 24px;
}
.incident-details .navbar-actions{
    margin-bottom: 10px;
}
.incident-details .navbar-actions .btn.btn-link{
    background: transparent;
    border-color: transparent;
}
.incident-details .navbar-actions .btn.btn-link a{
    color: #6C798F;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    text-decoration: none;
    border-radius: 4px;
}
.incident-details .navbar-actions .btn.btn-link a:hover{
    text-decoration:underline;
}
.incident-details .navbar-actions .btn.btn-link svg{
    width: 14px;
    height: 14px;
    margin-right: 8px;
}
.incident-details .navbar-actions .btn.btn-link svg *{
    fill: #6C798F;
}
.incident-details .act-inc_progressbar{
    width: 90%;
    max-width: 100%;
    margin: 20px auto;
    background: #d7d6d6;
    height: 10px;
}