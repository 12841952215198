@keyframes spinner {
  to {transform: rotate(360deg);}
}
.spinner{
    position : relative;
}
.spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 30%;
    left: 30%;
    width: 40px;
    height: 40px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-top-color: #2196F3;
    animation: spinner .6s linear infinite;
}
.spinner.small:before {
  width:20px;
  height: 20px;

}
.spinner.small.center:before {
  top: calc(50% - 10px);
  margin-top : 0;
}