.App {
	text-align: center;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}
.margin-bottom-10{
	margin-bottom:10px;
}
.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.separator {
	margin-bottom: 25px;
	margin-top: 25px;
}

.separator {
	border-bottom: 1px solid #efefef;
	display: flex;
	width: 100%;
}

.fluid {
	width: 100% !important;
	max-width: 100% !important;
}
.inline{
	display: inline!important;
}
/* BASIC FORM */
form {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	width: 100%;
}

form input,
form select {
	font-family: inherit;
}

form input:focus,
form select:focus {
	outline: none;
}

form input::placeholder,
form select::placeholder {
	color: #b9babd;
}

form .field-group {
	padding: 10px 2px;
	display: flex;
	flex-wrap: wrap;
	max-width: 500px;
}

form .field-group label {
	font-size: 13px;
	color: #525252;
	margin-bottom: 6px;
	align-items: flex-end;
}

form .field-group label span {
	font-size: 11px;
	color: #a6a6a6;
	margin-left: 6px;
}

form .field-group .textField {
	padding: 0.5rem;
	line-height: 1.5;
	outline: 0;
	border: 1px solid #c1c1c1;
	border-radius: 3px;
	background: #f5f5f54f;
}

form .field-group .textField:focus {
	border-color: #77a7ff;
}

form .field-group .textField.error {
	border-color: red;
}

form .field-group .fieldText_alert--error {
	font-size: 12px;
	color: red;
	padding-top: 4px;
}

.fieldText_note {
	font-size: 11px;
    color: #afafaf;
    padding: 2px 0;
    width: 100%;
    margin-top: 10px;
}
.fieldText_note a{
	display: inline!important;
	color: #0048cd;
}
form .field-group.submit-fields-group {
	justify-content: flex-start;
	align-items: flex-start;
}

form .field-group.submit-fields-group .alert {
	flex-grow: 1;
}

form .field-group.submit-fields-group .response-group {
	flex-grow: 1;
	margin-left: 10px;
}

/* NEW STYLE */
.hide {
	display: none !important;
}

.flex-display {
	display: flex !important;
}

.status-circle {
	width: 30px;
	height: 30px;
	display: flex;
	border-radius: 50%;
	color: #fff;
	text-align: center;
}

.status-circle.success {
	background-color: #4cdd53fa;
}

.status-circle.fail {
	background-color: #ff4949;
}

.status-circle.warning {
	background-color: #ffcc00;
}

body {
	margin: 0;
	padding: 0;
	font-family: Gordita, Helvetica Neue, Helvetica, Arial, sans-serif;
	font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
		Oxygen-Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif;
	font-size: 15px;
	line-height: 1.5;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	word-wrap: break-word;
	margin: 0;
	background: #f8f8f8;
}

.loading-wrapper {
	z-index: 999;

	display: flex;

	align-items: center;

	width: 100%;

	/* min-height: 40px; */

	justify-content: center;

	padding: 20px 0;

	/* position: absolute; */
}

.loading-wrapper .spinner {
	display: flex;
	width: 50px;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.loading-in-btn-wrapper{
	z-index: 999;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: center;
	padding: 0px 0;
}
.loading-in-btn-wrapper .spinner{
	display: flex;
    width: 20px;
    height: 13px;
    align-items: center;
    justify-content: center;
}
.loading-in-btn-wrapper .spinner:before{
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #f7f7f7;
    border-top-color: #ffc108;
    animation: spinner .6s linear infinite;
}
.white-card {
	border-radius: 4px;
	padding: 0;
	box-shadow: 0 0.125rem 0.1875rem 0 rgb(129 129 129 / 7%),
		0 0 0 0.0625rem rgb(222 222 222 / 17%);
	background: white;
	width: 100%;
	position: relative;
	padding: 20px 20px;
	/* display: flex; */
}

.container {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	max-width: 780px;
	/* padding: 20px; */
	margin: auto auto 0px;
}

.appContainer {
	background: #f8f8f8;
}

.mainContainer .navbar-wrapper .navbar-content {
	background: transparent;
	padding: 20px 0 0px 0;
}

.navbar-content .statuspage-logo {
	height: 40px;
}

.navbar-content .statuspage-logo img {
	height: 100%;
}

.navbar-content .statuspage-menu {
	flex-grow: 1;
	justify-content: flex-end;
}

.navbar-content .statuspage-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.navbar-content .statuspage-menu ul li {
	margin-left: 10px;
}

.navbar-content .statuspage-menu ul li a {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	line-height: 16px;
}

.navbar-content .statuspage-menu ul li a svg {
	width: 16px;
	height: 16px;
	margin-right: 8px;
}

.statuspage-menu a.dark {
	color: #191919;
}

.statuspage-menu a:hover {
	text-decoration: underline !important;
}

.statuspage-menu a.dark > svg {
	color: #252525;
}

.statuspage-menu a.light {
	color: #ffffff;
}

.statuspage-menu a.light > svg {
	color: #ffffff;
}

.mainContainer .global-header {
	position: relative;
}

.mainContainer .header-wrapper .header-content {
	margin-bottom: 50px;
	position: relative;
	z-index: 999;
}

.mainContainer .header-wrapper .header-bottom-polygon svg {
	position: absolute;
	width: 100%;
	height: 60px;
	bottom: 0;
}

.mainContainer .header-wrapper .header-bottom-polygon svg * {
	fill: #f8f8f8;
}

.mainContainer .header-content .status-wrapper {
	display: flex;
	padding: 22px 22px;
	flex-wrap: wrap;
	align-items: center;
	top: 20px;
	color: #525252;
}

.mainContainer .header-content .status-wrapper .status-circle.success {
	border: 1px solid #33d83a;
}

.mainContainer .header-content .status-wrapper .status-circle.fail {
	border: 1px solid #f44336;
}

.mainContainer .header-content .status-wrapper .status-circle.warning {
	border: 1px solid #eabb00;
}

.mainContainer
	.header-content
	.status-wrapper
	.status-circle.warning.integrate-img {
	background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iQ2FwYV8xIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDUxMiA1MTIiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGc+PHBhdGggZD0ibTI1NiA0MjJjLTI0Ljg1MyAwLTQ1IDIwLjE0Ny00NSA0NXMyMC4xNDcgNDUgNDUgNDUgNDUtMjAuMTQ3IDQ1LTQ1LTIwLjE0Ny00NS00NS00NXoiLz48cGF0aCBkPSJtMjU2IDBjLTI0Ljg1MyAwLTQ1IDIwLjE0Ny00NSA0NXYyOTljMCAyNC44NTMgMjAuMTQ3IDQ1IDQ1IDQ1czQ1LTIwLjE0NyA0NS00NXYtMjk5YzAtMjQuODUzLTIwLjE0Ny00NS00NS00NXoiLz48L2c+PC9zdmc+");
	background-size: 60%;
	background-position: center;
	background-repeat: no-repeat;
}

.mainContainer .header-content .status--icon_flag {
	padding-right: 20px;
}

.mainContainer .header-content .status--title {
	font-size: 14px;
	flex-grow: 1;
	line-height: 100%;
	color: #7d7d7d;
}

.mainContainer .header-content .status--global_state_vl {
	font-size: 28px;
	flex-grow: 1;
	font-weight: 400;
	color: #32325d;
}

.mainContainer .header-content .status--current-time {
	font-size: 14px;
	color: #06060661;
}

.mainContainer .header-content .incident-link {
	position: absolute;
	top: 18px;
	right: 20px;
}

.mainContainer .header-content .incident-link a {
	text-decoration: none;
	color: #172b4d;
	display: flex;
	align-items: center;
}

.mainContainer .header-content .incident-link a:hover {
	text-decoration: underline;
}

.mainContainer .header-content .incident-link a svg {
	width: 16px;
	height: 16px;
}

.mainContainer .header-content .incident-link a svg * {
	fill: #172b4d;
}

.is-partial-down .status--global_state_vl {
	color: orange !important;
}

.is-down .status--global_state_vl {
	color: red !important;
}

.monitors-services {
	padding: 0;
	width: 100%;
	position: relative;
}

.monitors-services .check-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 16px 20px;
	border-bottom: 1px solid #f4f4f4;
	margin-bottom: 20px;
}

.monitors-services .check-item .check-item__main-info {
	display: flex;
	align-items: center;
	width: 100%;
}

.monitors-services .check-item .check-item__status {
	display: flex;
	margin-right: 8px;
}

.monitors-services .check-item .check-item__name {
	flex-grow: 1;
	font-weight: 500;
	width: 100%;
	color: #172b4d;
}

.monitors-services .check-item .check-item__status-wrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	width: 100%;
	justify-content: flex-end;
	margin-top: 20px;
}
.monitors-services .check-item .check-item__status-wrapper.status-for-uptime-bar {
	
	margin-top: 0px;
}

.ci-avg_responsetime {
	font-size: 12px;
	border-radius: 4px;
	color: white;
	margin-left: 10px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	line-height: 18px;
}

.ci-avg_responsetime label {
	background: #3f51b6;
	font-size: 11px;
	padding: 1px 4px 1px 4px;
	border-radius: 3px 0 0 3px;
	background: #e6e6e6;
	color: #6d6d6d;
}

.ci-avg_responsetime span {
	background: #909090;
	padding: 1px 4px;
	border-radius: 0 3px 3px 0;
	font-size: 11px;
	font-weight: 600;
}

.ci-avg_uptime {
	font-size: 12px;
	border-radius: 4px;
	color: white;
	margin-left: 10px;
	display: flex;
	align-items: stretch;
	justify-content: center;
	line-height: 18px;
}

.ci-avg_uptime label {
	font-size: 11px;
	padding: 1px 4px 1px 4px;
	background: #e6e6e6;
	border-radius: 3px 0 0 3px;
	color: #333;
}

.ci-avg_uptime span {
	padding: 1px 4px;
	background: #6b6b6b;
	border-radius: 0 3px 3px 0;
	font-size: 11px;
	color: #fff;
	font-weight: 600;
}

.monitors-services .check-item .check-item__state {
	flex: 0 0 120px;
	display: flex;
}

.monitors-services .check-item .ci-state {
	color: #00dc00;
    flex-grow: 1;
    justify-content: flex-end;
    display: flex;
    font-weight: 500;
    font-size: 14px;
}

.monitors-services .check-item .ci-state.is-down {
	color: red;
}

.monitors-services .check-item .check-item__chart {
	width: 100%;
	/* margin-top: 10px; */
	display: flex;
	flex-wrap: wrap;
}

.monitors-services .check-item .check-item__chart .no-data {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.monitors-services .check-item .check-item__chart .no-data span {
	text-transform: uppercase;
	font-size: 10px;
	color: #6d6c6c;
	padding: 10px 0;
}

.monitors-services .date-filter {
	padding: 16px 10px 0 10px;
	border-bottom: 1px solid #e6e6e6;
	background: #ececec;
	border-radius: 4px 4px 0 0;
}

.monitors-services .date-filter ul {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: -1px;
}

.monitors-services .date-filter ul .selector-item {
	font-size: 14px;
	padding: 4px 10px;
	border-bottom-color: transparent;
	margin: 0 3px;
	border-radius: 3px 3px 0 0;
	cursor: pointer;
	background: white;
}

.monitors-services .date-filter ul .selector-item:hover {
	background: #f4f4f4;
}

.date-filter .selector-item.selected {
	z-index: 9999;
	border: 1px solid transparent;
}

.date-filter .selector-item.selected:hover {
	background: white !important;
}

.status-circle.big {
	width: 50px;
	height: 50px;
}

.status-circle svg {
	width: 100%;
	height: 100%;
}

.footer-wrapper {
	margin-bottom: 20px;
}

.footer-wrapper .footer-content .container {
	justify-content: flex-end;
	border-top: 1px solid #e2e2e2;
	padding-top: 10px;
}

.footer-wrapper .nav-items {
	flex-grow: 1;
}

.footer-wrapper .nav-items ul {
	list-style: none;
}

.footer-wrapper .nav-items ul li {
}

.footer-wrapper .nav-items ul li a {
	text-decoration: none;
	color: #172b4d;
}

.footer-wrapper .nav-items ul li a:hover {
	text-decoration: underline;
}

.footer-wrapper .footer-content .powered {
	font-size: 12px;
	color: #333;
}

.footer-wrapper .footer-content .powered a {
	color: #ababab;
	text-decoration: none;
}

.footer-wrapper .footer-content .powered .by {
	font-weight: 600;
	color: #006eec;
}

.not_found-container {
	margin: 20px;
	text-align: center;
}

.not_found-container h1 {
	font-size: 16px;
	font-weight: 400;
}

/** BTN */
.btn.btn-primary {
	border-color: #0d4be0;
	background-color: #1853db;
	color: #fff;
}

.btn.btn-primary:hover {
	background-color: #0d47a1;
	color: #fff;
}
