.global-incidents-header {
  margin-bottom: 20px;
}

.global-incidents-header .white-card {
}

.global-incidents-header h1 {
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.global-incidents-header.active-incidents h1 {
  color: #607d8b;
  display: flex;
  align-items: center;
}

.global-incidents-header.active-incidents svg {
  width: 20px;
}

.global-incidents-header.active-incidents svg * {
  fill: #ffb837;
}

.global-incidents-header .active-incidents-content {
  margin-top: 10px;
}

.active-incidents-content .act-inc_wrapper {
  margin-bottom: 3px;
  padding-bottom: 3px;
  /* border-bottom: 1px solid #f5f0f0; */
}

.active-incidents-content .act-inc_wrapper:last-child {
  border-bottom: 0px;
}

.active-incidents-content .act-inc_wrapper a {
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 10px;
}

.active-incidents-content .act-inc_wrapper a:hover {
  background: #c9c9c930;
}

.active-incidents-content .act-inc_wrapper .act-inc_row-main-info {
    width: 100%;
    display: flex;
    align-items: center;
}
.act-inc_progressbar{
    width: 33.333333333%;
    max-width: 200px;
    background: #cbcbcb;
    height: 8px;
    border-radius: 20px;
    display: flex;
    overflow: hidden;
}
.act-inc_pbar--content{
    background: #2196f3;
}
.act-inc_pbar--content.danger{
  background: red;
}
.act-inc_pbar--content.warning{
  background: #ffc107;
}
.act-inc_pbar--content.pre-success{
  background: #18b7ff;
}
.act-inc_pbar--content.success{
  background: #28d52f;
}
.active-incidents-content .act-inc_wrapper .act-inc_row-sec-info {
    width: 100%;
    display: flex;
    font-size: 12px;
    color: #6c798f;
    align-items: center;
}
.active-incidents-content .act-inc_wrapper .act-inc_row-sec-info .separator{
  padding: 0px 10px 8px 10px;
  width: auto;
  border: 0;
  margin: 0;
  font-size: 18px;
  line-height: 10px;
}
.active-incidents-content .act-inc_wrapper a .act-inc_title {
  font-size: 14px;
  color: #172b4d;
  flex-grow: 1;width: 66.6666666%;
  display: flex;
  align-items: center;
}
.active-incidents-content .act-inc__type{
  width: 14px;
  height: 18px;
  margin-right: 6px;
}
.active-incidents-content .act-inc__type svg{
  width: 100%;
  height: 100%;
}
.active-incidents-content .act-inc__type svg *{
  fill: #607d8b;stroke: #607d8b!important;
}
.active-incidents-content .act-inc_wrapper a .act-inc_event-msg {
  color: #8a8787;
  font-size: 14px;
  width: 100%;
  margin-bottom: 10px;
}

.active-incidents-content .act-inc_wrapper a .act-inc_event-date {
}

.active-incidents-content .act-inc_wrapper a .act-inc_event-type {
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 2px;
  line-height: 100%;    display: flex;
  align-items: center;
}
.active-incidents-content .act-inc_wrapper a .act-inc_event-counterDown{
  
}

.active-incidents-content .act-inc_event-type.investigating {
  background: #ff0c0454;
  color: #980400ab;
}

.active-incidents-content .act-inc_event-type.identified {
  background: #ffb10170;
  color: #4a4a4a;
}

.active-incidents-content .act-inc_event-type.monitoring {
  background: #219ebc45;
  color: #4c4c4c;
}

.active-incidents-content .act-inc_event-type.resolved {
  background: #18d22069;
  color: #0d8012;
}
.active-incidents-content .act-inc_event-type.scheduled ,
.active-incidents-content .act-inc_event-type.inprogress ,
.active-incidents-content .act-inc_event-type.verifing ,
.active-incidents-content .act-inc_event-type.completed {
  background: #efefef;
    color: #282828;
}
.line-clamp-1{
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
