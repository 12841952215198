.custom-tooltip{
    background-color: #0b0a33;
    /* background-color: #212e34; */
    opacity: .85;
    padding: 10px;
    border-radius: 5px;
    color: #fff;
    width: 260px;
    line-height: 1.5em;
    font-size: 12px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;    
}
.custom-tooltip.responseTime__chart-tooltip{
    flex-direction: column;
}
.custom-tooltip.responseTime__chart-tooltip .label{
    
}
.custom-tooltip .sub-label{
    font-size: 11px;
    font-weight: normal;
}


.custom-tooltip.responseTime__chart-tooltip .main-item{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ffffff4a;
    padding-bottom: 4px;
    margin-bottom: 4px;
    align-items: center;
}
.custom-tooltip.responseTime__chart-tooltip .main-item .lineColor{
    width: 10px;
    height: 10px;
    border-radius: 1px;
    margin-right: 10px;
}
.custom-tooltip.responseTime__chart-tooltip .main-item .label{
 flex-grow: 1;   
}

.custom-tooltip.responseTime__chart-tooltip .secondary-item{}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul{
    display: flex;
    flex-direction: column;
    
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 300;
    align-items: center;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .lineColor{
    width: 10px;
    height: 10px;
    border-radius: 1px;
    margin-right: 10px;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .label{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.custom-tooltip.responseTime__chart-tooltip .secondary-item ul li .value{}
.custom-tooltip.uptime__chart-tooltip{
    flex-direction: column;
}
.custom-tooltip.uptime__chart-tooltip .label{
    font-size: 14px;
    font-weight: normal;
}
.custom-tooltip.uptime__chart-tooltip .value{    font-size: 12px;
    padding-top: 5px;
    font-weight: 500;
}
.custom-tooltip.uptime__chart-tooltip .value.custom{
    display: flex;
    flex-direction: column;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .uptime-metric{
    margin-bottom: 2px;
    font-weight: 400;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .uptime-metric label{
    font-weight: 600;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .outage-delay{
    margin-bottom: 2px;
    font-weight: 400;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .outage-delay label{
    font-weight: 600;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .related-incidents{
    margin-bottom: 2px;
    font-weight: 400;
}
.custom-tooltip.uptime__chart-tooltip .value.custom .related-incidents label{
    font-weight: 500;
}
.custom-tooltip.uptime__chart-tooltip .value.custom ul.related-events-list{
    padding-left: 20px;
    margin-bottom: 0;
}
.custom-tooltip.uptime__chart-tooltip .value.custom li.related-event{
    margin-bottom: 4px;
}
.custom-tooltip.uptime__chart-tooltip .value.custom li.related-event a{
    color: white;
    text-decoration: none;
    display: flex;
    align-items: center;
    line-height: 14px;
}
.custom-tooltip.uptime__chart-tooltip .value.custom li.related-event a svg{
    width: 18px;
    height: 14px;
    margin-right: 5px;
    flex: 0 0 18px;
}
.custom-tooltip.uptime__chart-tooltip .value.custom li.related-event a svg *{
    fill:white;
}
.custom-tooltip.uptime__chart-tooltip .value.custom li.related-event a span{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.recharts-tooltip-wrapper{
    z-index: 9999;
    /* pointer-events: all!important; */
}
path.recharts-rectangle:hover {
    fill: #607d8b;
}
/** UPTIME BARS > LEGEND */
.recharts-legend-wrapper .custom-legend{
    display: flex;
    color: #b5b5b5;
    font-size: 12px;
    padding: 3px 0;
    align-items: center;
}
.recharts-legend-wrapper .custom-legend .light{}
.recharts-legend-wrapper .custom-legend .legend-item{} 
.recharts-legend-wrapper .custom-legend .legend-item-date-range{}
.recharts-legend-wrapper .custom-legend .availability-time-line-legend-day-count{}
.recharts-legend-wrapper .custom-legend .spacer{
    flex: 1;
    margin: 0 16px;
    height: 1px;
    background: #aaa;
    opacity: 0.3;
}
.recharts-legend-wrapper .custom-legend .legend-item-uptime-value{}
.recharts-legend-wrapper .custom-legend .uptime-percent{}